import {useState, useEffect} from 'react'
import axios from 'axios'

const useFetch = (urlPath) => {
    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        const controller = new AbortController()
        // efi turant call back wale functions
        //semcolon added for sefty
        ;(async () => {
            try {
                setError(false)
                setLoading(true)
                const response = await axios.get(urlPath, {
                    signal: controller.signal
                })
                // console.log(response.data);
                // console.log(response);
                setData(response.data)
                setLoading(false)
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log("Request canceled");
                    return
                }
                setLoading(false)
                setError(true)
            }
        })()
        //cleanup
        return () => {
            controller.abort()
        }
    },[urlPath])

    return {data, error, loading}
}
export default useFetch