import React from 'react'
import { Link } from 'react-router-dom'
import useFetch from '../customs/Fetch'

const Footer = () => {
  const {data, error, loading} = useFetch(`https://manoharjwellers.com/api/api/category.php`)
  // useEffect(() => {},[data])

  return (
    <footer>
      {error && ''}
      {loading ? <p>Loading...</p> :
      <ul className="footer__categories">
        
        {
          data.category &&
           data.category.map(({catRef, catEN, catHI, index}) =>{ 
            return <li key={index}><Link to={`categories/${catRef}/0`}>{catEN}</Link></li>}
            )
        }
        {
          data.subCategory &&
           data.subCategory.map(({sCatRef, sCatEN, sCatHI, index}) =>{
             return <li key={index}><Link to={`categories/0/${sCatRef}`}>{sCatHI}</Link></li>}
            )
        }
      </ul>}
      <div className="footer__copyright">
        <p>All Rights Reserved &copy; Copyright, Seven Pings.</p>
      </div>
    </footer>
  )
}

export default Footer