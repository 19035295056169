import React from 'react'
import useFetch from '../customs/Fetch'
import PostItemSkeleton from '../components/PostItemSkeleton'
import PostItemReal from '../components/PostItemReal'
import {encode} from 'base-64'

const Posts = () => {
    const obj = {'isFeatured':'N', 'catRef':0, 'sCatRef':0}
    let token = encode(JSON.stringify(obj)).replace('=','')
    const {data, error, loading} = useFetch(`https://manoharjwellers.com/api/api/read.php?token=${token}`)
     return (
        <>
            {error && <h2 className="center">Something went wrong. Oops!!!</h2>}
            <section className='posts'>
            {
                loading ? 
                <div className="container posts__container"><PostItemSkeleton /><PostItemSkeleton /><PostItemSkeleton /></div> :
                data.length > 0 ? <div className="container posts__container">
                { data.map(({itemRef, catRef, sCatRef, imgURI, imgURIs, itemHI, itemEN, descHI, descEN, catHI, catEN, createdTime}) =>
                    <PostItemReal key={itemRef} postID={itemRef} catRef={catRef} sCatRef={sCatRef} thumbnail={imgURI} itemEN={itemEN} itemHI={itemHI} descHI={descHI} descEN={descEN} catHI={catHI} catEN={catEN} authorID={1} createdAt={createdTime} imgURIs={imgURIs}/>
                )}
                </div> : <h2 className="center">No posts yet!!!</h2>
            }
            </section>
        </>
    )
}

export default Posts