import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './components/Layout';
import ErrorPage from './pages/ErrorPage';
import Home from './pages/Home';
import PostDetail from './pages/PostDetail';
import Posts from './pages/Posts';
import CategoryPosts from './pages/CategoryPosts';
import Clips from './pages/Clips';
import FeaturedPosts from './pages/FeaturedPosts';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorPage/>,
    children: [
      {index: true, element: <Home/>},
      {path: "/", element: <Posts/>},
      {path: "items/:itemRef", element: <PostDetail/>},
      {path: "categories/:catRef/:sCatRef", element: <CategoryPosts/>},
      {path: "clips", element: <Clips/>},
      {path: "featured-items", element: <FeaturedPosts/>}
    ]  
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);