import React from 'react'
import useFetch from '../customs/Fetch'
import PostItemSkeleton from '../components/PostItemSkeleton'
import PostItemReal from '../components/PostItemReal'
import {encode} from 'base-64';
import { useParams } from 'react-router-dom';


const CategoryPosts = () => {
  const {catRef,sCatRef} = useParams();
  
  const obj = {'isFeatured':'Y', 'catRef':catRef, 'sCatRef':sCatRef}
  let token = encode(JSON.stringify(obj)).replace('=','')
  const {data, error, loading} = useFetch(`https://manoharjwellers.com/api/api/read.php?token=${token}`)
  return (
    <>
        {error && <h2 className="center">Something went wrong. Oops!!!</h2>}
        <section className='posts'>
          {loading ? <div className="container posts__container"><PostItemSkeleton /><PostItemSkeleton /><PostItemSkeleton /></div> :
            data.length > 0 ? <div className="container posts__container">
              { 
                data.map(({itemRef, catRef, sCatRef, imgURI, imgURIs, itemHI, itemEN, descHI, descEN, catHI, catEN, createdTime}) =>
                  <PostItemReal key={itemRef} postID={itemRef} catRef={catRef} sCatRef={sCatRef} thumbnail={imgURI} itemEN={itemEN} itemHI={itemHI} descHI={descHI} descEN={descEN} catHI={catHI} catEN={catEN} authorID={1} createdAt={createdTime}  imgURIs={imgURIs}/>) 
              }
            </div> : <h2 className="center">No posts yet!!!</h2>
          }
        </section>
    </>
  )
}

export default CategoryPosts