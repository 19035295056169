import React from 'react'
import './PostDetail.scss';
import PostAuthor from '../components/PostAuthor'
import { Link, useParams } from 'react-router-dom'
// import Thumbnail from '../images/post.jpg'
// import useFetch from '../customs/Fetch'
// import {encode} from 'base-64'
import ImageSlider from '../components/ImageSlider';

const PostDetail = () => {
  // const { itemRef } = useParams
  // const obj = {'itemRef':itemRef}
  // let token = encode(JSON.stringify(obj)).replace('=','')
  // const {data, error, loading} = useFetch(`https://manoharjwellers.com/api/api/read.php?token=${token}`)
  return (
    <section className='post-detail'>
      <div className="container post-detail__container">
        <div className="post-detail__header">
          <PostAuthor/>
          <div className="post-detail__buttons">
            <Link to={`/posts/werwer/edit`} className='btn sm primary'>Edit</Link>
            <Link to={`/posts/werwer/delete`} className='btn sm danger'>Delete</Link>
          </div>
        </div>
        <h1>This the post Title</h1>
        <div className="post-detail_thumbnail">
          <ImageSlider />
        </div>
        <p></p>
      </div>
    </section>
  )
}

export default PostDetail