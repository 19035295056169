import React from 'react'
import imgPlaceholder from '../images/images.png'
import Avatar from '../images/avatar.png'

const PostItemSkeleton = () => {
    
    return (
    <article className="post">
        <div className="post_thumbnail">
            <img src={imgPlaceholder} alt="image"/>
        </div>
        <div className="skeleton-post__content">
            <h3>Title</h3>
            <small>Lorem ipsum dolor sit amet consectetur.
            </small>
            <div className="post__footer">
                <div className="post__author-avatar">
                    <img src={Avatar} alt="" />    
                </div>
                <div className="skeleton-post__author-details">
                    <h5>By: Author Name</h5>
                    <small>Just Now</small>
                </div>
                <p className='btn category'>Button</p>
            </div>
        </div>
    </article>
  )
}

export default PostItemSkeleton