import React from 'react'
import { Outlet } from 'react-router-dom'

import Header from './Header'
import Footer from './Footer'
import FlotingNavbar from './FlotingNavbar'

const Layout = () => {
  
  return (
    <>
        <Header />
        <Outlet/>
        <Footer />
        <FlotingNavbar />
    </>
  )
}

export default Layout