import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { AiOutlineHome } from 'react-icons/ai'
import { FaHotjar } from 'react-icons/fa'
import { IoVideocamOutline } from 'react-icons/io5'

const FlotingNavbar = (nav) => {
  const [activeNav, setActiveNav] = useState(nav);    
  return (
    <nav className="floting-nav">
        <ul>
            <Link to="/featured-items" onClick={() => setActiveNav("/featured-items")}><li className={activeNav === "/featured-items" ? 'active' : ''}><FaHotjar /></li></Link>
            <Link to="/" onClick={() => setActiveNav("/")}><li className={activeNav === "/" ? 'active' : ''}><AiOutlineHome /></li></Link>
            <Link to="/clips" onClick={() => setActiveNav("/clips")}><li className={activeNav === "/clips" ? 'active' : ''}><IoVideocamOutline /></li></Link>
        </ul>
    </nav>
  )
}

export default FlotingNavbar