import React, { lazy } from 'react'
import { Link } from 'react-router-dom'
import PostAuthor from './PostAuthor'
import imgPlaceholder from '../images/images.png'
import ImageSlider from './ImageSlider'

const PostItemReal = ({postID, catRef, sCatRef, thumbnail, itemEN, itemHI, descHI, descEN, catHI, catEN, authorID, createdAt, imgURIs}) => {
    
    // const shortDescription = description.length > 145 ? description.substr(0,145) + '...' : description;
    const srtItemHI = itemHI.length > 30 ? itemHI.substr(0,30) + '...' : itemHI;
    const srtItemEN = itemEN.length > 30 ? itemEN.substr(0,30) + '...' : itemEN;
    thumbnail = thumbnail ? thumbnail : imgPlaceholder
    return (
    <article className="post">
        <div className="post_thumbnail">
            {/* <img src={thumbnail} alt={itemHI}/> */}
            <ImageSlider imgURIs={imgURIs}/>
        </div>
        <div className="post__content">
            {/* <Link to={`/items/${postID}`}> */}
                <h3>{srtItemHI} / {srtItemEN}</h3>
            {/* </Link> */}
            <p>{descHI} / {descEN}</p>
            <div className="post__footer">
                <PostAuthor authorID={authorID} createdAt={createdAt}/>
                <Link to={`/categories/${catRef}/0`} className='btn category'>{catEN}</Link>
            </div>
        </div>
    </article>
  )
}

export default PostItemReal