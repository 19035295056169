import React from 'react'
import { FaTruckLoading } from "react-icons/fa";

const Clips = () => {
  return (
    <>
      <div className='center' style={{fontSize: 50, marginTop: 20}}>Still Working on this ....</div>
      <i className='center' style={{fontSize: 100}}><FaTruckLoading /></i>
    </>
  )
}

export default Clips